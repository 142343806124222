<template>
<div class="container" v-if="user">
    <h4 class="header">Administrador de Ordenes</h4>
    <div v-show="!$g.busy">
        <div v-if="!(data && data[0])" class="card-panel">No se han encontrado pedidos vigentes</div>
        <table v-else class="table">
            <thead>
                <tr>
                    <th>Orden</th>
                    <th>Comentarios</th>
                    <th class="right-align">Fecha</th>
                    <th class="right-align">Estado</th>
                    <th class="right-align">Total</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in data" :key="item.Key">
                    <td>
                        <router-link :to="'/order/' + item.Key + '?uid=' + getUser(item)">{{item.Key}}</router-link>
                    </td>
                    <td>{{ item.Comments }}</td>
                    <td class="right-align">{{ formatDateTime(item.Date) }}</td>
                    <td class="right-align"><a href="#" @click.prevent="changeStatus(item)">{{ item.Status }}</a></td>
                    <td class="right-align">{{ formatMoney(item.Total) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div id="modalStatus" class="modal modal-fixed-footer bottom-sheet1">
    <div class="modal-content">
        <h4>Cambiar status</h4>
        <form class="col s6">
            <div class="row">
                <div class="input-field col s12">
                    <select v-model="form.Status">
                        <option value="PENDIENTE">PENDIENTE</option>
                        <option value="PAGADO">PAGADO</option>
                        <option value="DESPACHADO">DESPACHADO</option>
                        <option value="ENTREGADO">ENTREGADO</option>
                        <option value="ANULADO">ANULADO</option>
                    </select>
                    <label>Estatus</label>
                </div>
            </div>
            <div class="row">
                <div class="input-field col s12">
                    <textarea placeholder="Comentarios" class="materialize-textarea" id="txtComments" type="text" v-model="form.Comments"></textarea>
                    <label for="txtComments">Comentarios</label>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <a href="#!" class="modal-close waves-effect waves-light btn-flat">Cerrar</a>&nbsp;
        <a href="#!" class="waves-effect waves-light btn" @click.prevent="saveStatus"><i class="material-icons left">save</i>Guardar</a>&nbsp;
    </div>
</div>
</template>

<script>
import {
    Base
} from '../../base'
import {
    where
} from "../../firebase";
import OrderModel from '../../models/OrderModel'
import M from 'materialize-css'

var orders = new OrderModel();
var modalEdit;

export default {
    mixins: [Base],
    data() {
        return {
            uid: null,
            data: [],
            form: {}
        }
    },
    methods: {
        getUser(item) {
            var arr = item.Ref.path.split('/');
            return arr[1];
        },
        async saveStatus() {
            modalEdit.close();
            this.$g.busy = true;
            var item = await orders.getByRef(this.form.Ref);
            if (item.Status != this.form.Status) {
                item.Status = this.form.Status;
                item.Date = new Date();
                item.Comments = this.form.Comments ? this.form.Comments : '';
                item.History.push({
                    Status: item.Status,
                    Date: item.Date,
                    Comments: item.Comments
                });
                await orders.setByRef(this.form.Ref, item);
            }
            this.$g.busy = false;
        },
        changeStatus(item) {
            this.form = {
                Key: item.Key,
                Status: item.Status,
                Ref: item.Ref
            }
            modalEdit.open();
        },
        async onAuth() {
            orders.subscribeAdmin(where('Status', '!=', 'PENDIENTE'), data => {
                this.data = data;
                this.$g.busy = false;
            })
        }
    },
    async mounted() {
        modalEdit = this.initModal('modalStatus', {
            onOpenEnd: () => {
                this.updateTextFields();
                this.resizeTextArea('txtComments');
                var elems = document.querySelectorAll('select');
                M.FormSelect.init(elems);
                document.getElementById('txtComments').focus();
            }
        });
    },
    unmounted() {
        if (!this.user) return;
        orders.unsuscribe();
    }
}
</script>

<style scoped>
</style>
