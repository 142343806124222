import HomeView from './components/HomeView.vue';
import ProductsView from './components/ProductsView.vue';
import ProductDetailsView from './components/ProductDetailsView.vue';
import OrdersView from './components/OrdersView.vue';
import OrderDetailsView from './components/OrderDetailsView.vue';
import ProfileView from './components/ProfileView.vue';
import CartView from './components/CartView.vue';
import PaymentView from './components/PaymentView.vue';
import FeedbackView from './components/FeedbackView.vue';
import AdminProductsView from './components/admin/AdminProductsView.vue';
import AdminPortalView from './components/admin/AdminPortalView.vue';
import AdminOrdersView from './components/admin/AdminOrdersView.vue';
import UnauthorizedView from './components/UnauthorizedView.vue';
import TutorialESPBASIC from './components/tutoriales/TutorialESPBASIC.vue';

export default [{
  name: 'Home',
  path: '/',
  component: HomeView,
  meta: {
    adminRequired: false,
  }
}, {
  path: '/orders',
  component: OrdersView,
  meta: {
    adminRequired: false,
  }
}, {
  path: '/order/:id',
  component: OrderDetailsView,
  meta: {
    adminRequired: false,
  }
}, {
  path: '/profile',
  component: ProfileView,
  meta: {
    adminRequired: false,
  }
}, {
  path: '/store',
  component: ProductsView,
  meta: {
    adminRequired: false,
  }
}, {
  path: '/cart',
  component: CartView,
  meta: {
    adminRequired: false,
  }
}, {
  path: '/payment',
  component: PaymentView,
  meta: {
    adminRequired: false,
  }
}, {
  path: '/feedback',
  component: FeedbackView,
  meta: {
    adminRequired: false,
  }
}, {
  path: '/product/:id',
  component: ProductDetailsView,
  meta: {
    adminRequired: false,
  }
}, {
  path: '/admin/products',
  component: AdminProductsView,
  meta: {
    adminRequired: true,
  }
}, {
  path: '/admin/portal',
  component: AdminPortalView,
  meta: {
    adminRequired: true,
  }
}, {
  path: '/admin/orders',
  component: AdminOrdersView,
  meta: {
    adminRequired: true,
  }
}, {
  path: '/unauthorized',
  name: 'Unauthorized',
  component: UnauthorizedView,
  meta: {
    authRequired: false,
  }
}, {
  path: '/tutoriales/ESPBASIC',
  component: TutorialESPBASIC,
  meta: {
    authRequired: false,
  }
}];