<template>
<div class="container">
    <h3 class="header">Acceso no autorizado</h3>
    <router-link to="/">Home</router-link>
</div>
</template>

<script>
import {
    Base
} from '../base'

export default {
    mixins: [Base],
    mounted() {
        this.$g.busy = false;
    }
}
</script>
