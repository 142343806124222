<template>
<main>
    <div class="parallax-container">
        <div class="parallax"><img src="/img/comp1.jpg" /></div>
    </div>
    <div class="section white">
        <div class="row container">
            <blockquote>
                <h2 class="header">Bienvenido</h2>
            </blockquote>
            <p class="grey-text text-darken-3 lighten-3 flow-text">
                Somos es una empresa tecnológica orientada al desarrollo de soluciones
                que integran la electrónica y el software dentro de un mismo
                ecosistema de tal forma de brindar herramientas que unen el mundo real
                con el mundo virtual.
            </p>
            <p class="grey-text text-darken-3 lighten-3 flow-text">
                Contamos con la experiencia para desarrollar sus ideas con
                creatividad, entusiasmo y pasión, poniendo nuestro mejor esfuerzo para
                que el producto resultante tenga un alto grado de calidad y
                satisfacción para nuestros clientes.
            </p>
        </div>
    </div>
    <div class="parallax-container">
        <div class="parallax"><img src="/img/elec5.jpg" /></div>
    </div>
    <div class="section white">
        <div class="row container">
            <blockquote>
                <h2 class="header">Nuestros servicios</h2>
            </blockquote>
            <div class="row">
                <div class="col s12 m4">
                    <div class="card hoverable">
                        <div class="card-image">
                            <img src="/img/mobi3.jpg" />
                            <span class="card-title">Aplicaciones móviles</span>
                        </div>
                        <div class="card-content">
                            <p>
                                Desarrollamos aplicaciones móviles para plataformas Android y
                                iPhone de tal forma de dar portabilidad a su negocio,
                                integrándose con la información de sus sistemas legados tales
                                como SAP y otros, otorgando valor a su fuerza móvil.
                            </p>
                            <br />
                            <p>
                                Para ello utilizamos tecnologías híbridas que nos permite
                                minimizar los tiempos de desarrollo para tener una rápida
                                puesta en producción.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col s12 m4">
                    <div class="card hoverable">
                        <div class="card-image">
                            <img src="/img/web1.jpg" />
                            <span class="card-title grey-text text-darken-4">Aplicaciones Web</span>
                        </div>
                        <div class="card-content">
                            <p>
                                Desarrollamos soluciones orientadas a los procesos de negocio
                                que integran estructuras de datos junto con interfaces web
                                amigables y livianas, todo esto basado en los últimos
                                estándares de la industria.
                            </p>
                            <br />
                            <p>
                                De esta forma brindamos a las empresas una optimización en sus
                                procesos de negocios agregando valor a su cadena comercial, y
                                a nuestros usuarios una grata experiencia de uso.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col s12 m4">
                    <div class="card hoverable">
                        <div class="card-image">
                            <img src="/img/elec5.jpg" />
                            <span class="card-title">Sistemas electrónicos</span>
                        </div>
                        <div class="card-content">
                            <p>
                                Diseñamos y construimos sistemas electrónicos, utilizando
                                microcontroladores de última generación, que puedan realizar
                                funciones específicas.
                            </p>
                            <br />
                            <p>
                                Estos sistemas pueden leer información desde variados tipos de
                                sensores, transmitirlas a diferentes plataformas y generar
                                acciones usando diversos actuadores, integrando efectívamente
                                el mundo virtual con el real.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="parallax-container">
        <div class="parallax"><img src="/img/mobi3.jpg" /></div>
    </div>
    <div class="section white">
        <div class="row container">
            <blockquote>
                <h2 class="header">Contacto</h2>
            </blockquote>
            <p class="grey-text text-darken-3 lighten-3 flow-text">
                Si desea contactarnos por favor escríbamos un mensaje en el formulario
                adjunto y le responderemos a la brevedad.
            </p>
        </div>
        <div class="row container">
            <div class="row">
                <form class="col s12 offset-m3 m6" id="form1">
                    <input type="hidden" id="txtTarget" />
                    <div class="row">
                        <div class="col s12 center-align">
                            <h4 class="light">Formulario de contacto</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12">
                            <input id="txtNombre" name="txtNombre" type="text" :class="['validate', {'invalid': !form.nombre}]" autocomplete="false" v-model="form.nombre" />
                            <label for="txtNombre">Nombre</label>
                        </div>
                        <div class="input-field col s12">
                            <input id="txtEmpresa" type="text" :class="['validate', {'invalid': !form.empresa}]" autocomplete="false" v-model="form.empresa" />
                            <label for="txtEmpresa">Empresa</label>
                        </div>
                        <div class="input-field col s12">
                            <input id="txtEmail" name="txtEmail" type="email" :class="['validate', {'invalid': !form.email}]" autocomplete="false" v-model="form.email" />
                            <label for="txtEmail">Email</label>
                        </div>
                        <div class="input-field col s12">
                            <textarea id="txtMensaje" name="txtMensaje" :class="['validate materialize-textarea', {'invalid': !form.mensaje}]" autocomplete="false" v-model="form.mensaje"></textarea>
                            <label for="txtMensaje">Mensaje</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col s12 center-align">
                            <button class="btn waves-effect waves-light" id="cmdEnviar1" @click.prevent="send">
                                Enviar<i class="material-icons right">send</i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</main>

<FooterView>
    <template #footer-content>
        <div class="container">
            <div class="row">
                <div class="col m4 s12">
                    <h5 class="white-text">Innovación</h5>
                    <p class="grey-text text-lighten-4">
                        Buscamos nuevas formas de hacer las cosas ya que creemos que siempre
                        podemos mejorar.
                    </p>
                </div>
                <div class="col m4 s12">
                    <h5 class="white-text">Compromiso</h5>
                    <p class="grey-text text-lighten-4">
                        Llegamos hasta el final con el fin de lograr el objetivo.
                    </p>
                </div>
                <div class="col m4 s12" style="overflow: hidden">
                    <h5 class="white-text">Pasión</h5>
                    <p class="grey-text text-lighten-4">
                        Nos gusta lo que hacemos y ponemos toda nuestra fuerza en ello.
                    </p>
                </div>
            </div>
        </div>
    </template>
</FooterView>
</template>

<script>
import {
    Base
} from '../base'
import {
    dbPortal,
    child,
    get,
    update,
    push
} from "../firebase";

export default {
    mixins: [Base],
    data() {
        return {
            form: {}
        }
    },
    methods: {
        send() {
            if (!this.form.nombre || !this.form.empresa || !this.form.email || !this.form.mensaje) {
                this.showMessage('Error', 'Debe ingresar el contenido del formulario');
                return;
            }
            var nombre = this.form.nombre;
            var empresa = this.form.empresa;
            var email = this.form.email;
            var mensaje = this.form.mensaje;
            const postKey = push(child(dbPortal, 'Mensajes')).key;
            const postData = {
                Nombre: nombre,
                Empresa: empresa,
                Email: email,
                Mensaje: mensaje,
                Fecha: new Date(),
                Nuevo: true
            };
            var updates = {};
            updates['Mensajes/' + postKey] = postData;
            update(dbPortal, updates).then(() => {
                this.showMessage('Mensaje recibido', 'Hola <b>' + this.form.nombre + '</b>, te confirmamos que hemos recepcionado tu mensaje. Trataremos de ponernos en contacto lo antes posible');
                this.form = {}
            }, (err) => {
                alert('Error: ' + err.message);
            });
        }
    },
    async created() {
        var updates = {};
        var counter = 0;
        var snapshot = await get(child(dbPortal, 'Visitas'));
        var val = snapshot.val() || 0;
        counter = val + 1;
        updates["Visitas"] = counter;
        update(dbPortal, updates);
    },
    mounted: function () {
        this.$g.busy = false;
    }
};
</script>

<style scoped>
.toast {
    word-break: normal;
}

.parallax-container {
    height: 400px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .parallax-container {
        height: 250px;
    }
}
</style>
