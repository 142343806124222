import Settings from '../settings'

const settings = new Settings();

export default class CartModel {
    delete(item) {
        var carro = this.list();
        const item2 = carro.find(x => x.Key == item.Key);
        const index = carro.indexOf(item2);
        carro.splice(index, 1);
        settings.setSetting('cart', carro);
        return carro;
    }

    update(item) {
        var carro = this.list();
        const item2 = carro.find(x => x.Key == item.Key);
        const index = carro.indexOf(item2);
        if (item.Quantity <= 0) {
            item.Quantity = 1;
        }
        item.Total = item.Product.Price * item.Quantity;
        carro[index] = item;
        settings.setSetting('cart', carro);
        return carro;
    }

    list() {
        return settings.getSetting('cart', []);
    }

    clear() {
        var data = [];
        settings.setSetting('cart', data);
        return data;
    }

    buy(item) {
        var payload = {
            Key: item.Key,
            Product: {
                Name: item.Name,
                Price: item.Price
            },
            Quantity: 1,
            Total: item.Price
        };
        var carro = this.list();
        var data = carro.find(x => x.Key == item.Key);
        if (data) {
            const index = carro.indexOf(data);
            payload.Quantity = data.Quantity + 1;
            payload.Total = item.Price * payload.Quantity;
            carro[index] = payload;
        } else {
            carro.push(payload);
        }
        settings.setSetting('cart', carro);
    }
}