<template>
<br>
<footer class="page-footer" v-if="!$g.busy">
    <slot name="footer-content"></slot>
    <div class="footer-copyright">
        <div class="container">©2025 Binatrix, todos los derechos reservados</div>
    </div>
</footer>
</template>

<script>
export default {
    data() {
        return {};
    }
}
</script>

<style>
</style>
