<template>
<div class="container" v-if="user">
    <div class="row">
        <h4 class="header col m12">Detalles del pedido</h4>
    </div>
    <div v-if="!$g.busy">
        <div v-if="!data" class="card-panel">El pedido no existe</div>
        <div v-else>
            <div class="row">
                <div class="col m6">
                    <h5>Orden de compra</h5>
                    <table class="table">
                        <tr>
                            <td>Orden</td>
                            <td>{{ data.Key }}</td>
                        </tr>
                        <tr>
                            <td>Estado</td>
                            <td>{{ data.Status }}</td>
                        </tr>
                        <tr>
                            <td>Fecha</td>
                            <td>{{ formatDateTime(data.Date) }}</td>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td>{{ formatMoney(data.Total) }}</td>
                        </tr>
                        <tr>
                            <td>ID MercadoPago</td>
                            <td>{{ data.PaymentID }}</td>
                        </tr>
                        <tr>
                            <td>Comentarios</td>
                            <td>{{ data.Comments }}</td>
                        </tr>
                    </table>
                </div>
                <div class="col m6" v-if="data.Profile">
                    <h5>Destinatario</h5>
                    <div class="card-panel" v-html="formatProfile(data.Profile)"></div>
                    <!-- <table class="table">
                        <tr>
                            <td>Nombre</td>
                            <td>{{ data.Profile.Name }}</td>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td>{{ data.Profile.Email }}</td>
                        </tr>
                        <tr>
                            <td>Fono</td>
                            <td>{{ data.Profile.Phone }}</td>
                        </tr>
                        <tr>
                            <td>Dirección</td>
                            <td v-html="formatAddress(data.Profile.Address)"></td>
                        </tr>
                    </table> -->
                </div>
            </div>
            <div class="row">
                <div class="col m12">
                    <h5>Productos</h5>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Producto</th>
                                <th class="right-align">Precio Unitario</th>
                                <th class="right-align">Cantidad</th>
                                <th class="right-align">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in data.Products" :key="item.Key">
                                <td>
                                    <router-link :to="'/product/' + item.Key">{{ item.Product.Name }}</router-link>
                                </td>
                                <td class="right-align">{{ formatMoney(item.Product.Price) }}</td>
                                <td class="right-align">{{ item.Quantity }}</td>
                                <td class="right-align">{{ formatMoney(item.Total) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col m12">
                    <h5>Historial de eventos</h5>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Estado</th>
                                <th>Fecha</th>
                                <th>Comentarios</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in data.History.sort(x => x.Date).reverse()" :key="item.Status">
                                <td>{{ item.Status }}</td>
                                <td>{{ formatDateTime(item.Date) }}</td>
                                <td>{{ item.Comments }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<FooterView />
</template>

<script>
import {
    Base
} from '../base'
import OrderModel from '../models/OrderModel'

var orders = new OrderModel();

export default {
    mixins: [Base],
    data() {
        return {
            data: null
        }
    },
    methods: {
        async getOrder(id, uid = null) {
            if (!uid) uid = this.user.uid;
            this.data = await orders.get(uid, id);
        },
        async onAuth() {
            var id = this.$route.params.id;
            var uid = this.$route.query.uid;
            await this.getOrder(id, uid);
            this.$g.busy = false;
        }
    }
};
</script>

<style scoped>
/* override styles when printing */
@media print {

    body {
        margin: 0;
        color: #000;
        background-color: #fff;
    }

}
</style>
