import {
    dbProducts,
    getDocs,
    getDoc,
    setDoc,
    addDoc,
    doc,
    query,
    listAll,
    sref,
    fsProducts,
    getDownloadURL,
    onSnapshot,
    deleteDoc,
    deleteObject,
    uploadBytesResumable
} from "../firebase";

import BaseModel from './BaseModel'

export default class ProductModel extends BaseModel {
    unsubscribe;

    subscribe(onData) {
        this.unsubscribe = onSnapshot(dbProducts, querySnapshot => {
            var res = [];
            querySnapshot.forEach(childSnapshot => {
                var data = this.toData(childSnapshot);
                res.push(data);
            });
            res.sort((x, y) => x.Available - y.Available).reverse();
            onData(res);
        });
    }

    unsuscribe() {
        if (!this.unsubscribe) return;
        this.unsubscribe();
    }

    async list(filter, onImage) {
        var snapshot = await getDocs(query(dbProducts, filter));
        var arr = this.toArray(snapshot);
        var res = [];
        for (var childSnapshot of arr) {
            var data = this.toData(childSnapshot);
            data.Images = [{
                Url: '/img/blank.png'
            }];
            res.push(data);
            if (onImage) {
                this.loadImages(data.Key).then(doc => {
                    onImage(doc);
                });
            }
        }
        return res;
    }

    async get(id, onImage) {
        var snapshot = await getDoc(doc(dbProducts, id));
        var data = this.toData(snapshot);
        data.Images = [{ Url: '/img/blank.png' }];
        if (onImage) {
            this.loadImages(data.Key).then(doc => {
                onImage(doc);
            });
        }
        return data;
    }

    async loadImages(id) {
        var items = [];
        var images = await listAll(sref(fsProducts, id));
        var arr = this.toArray(images.items);
        for (var p of arr) {
            var url = await getDownloadURL(p);
            items.push({
                Name: p.name,
                Path: `${id}/${p.name}`,
                Url: url
            });
        }
        return { key: id, items: items };
    }

    async deleteImage(imageRef) {
        await deleteObject(sref(fsProducts, imageRef));
    }

    async addImage(file, id, name, onSuccess, onError, onEnd) {
        var filename = `${id}/${name}`;
        const storageRef = sref(fsProducts, filename);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on("state_changed",
            () => {
                onSuccess();
            },
            (error) => {
                onError(error);
            },
            () => {
                onEnd();
            }
        );
    }

    async delete(id) {
        var arr = (await this.loadImages(id).items);
        if (arr) {
            for (var file of arr) {
                await deleteObject(sref(fsProducts, file.Path));
            }
        }
        await deleteDoc(doc(dbProducts, id));
    }

    async set(item) {
        var data = {
            Available: false
        }
        if (item.Name) data.Name = item.Name;
        if (item.Description) data.Description = item.Description;
        if (item.Price) data.Price = item.Price;
        if (item.Available) data.Available = true;
        if (!item.Key) {
            var ref = await addDoc(dbProducts, data);
            item.Key = ref.id;
        } else {
            await setDoc(doc(dbProducts, item.Key), data);
        }
        return item;
    }
}
