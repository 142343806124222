<template>
<HeaderView />
<router-view />
</template>

<script>
import HeaderView from './components/HeaderView.vue'

export default {
    name: 'App',
    components: {
        HeaderView
    }
}
</script>

<style>
.header {
    color: #ee6e73;
    font-weight: 300;
}

.row {
    margin-bottom: 3px !important;
}
</style>
