<template>
<div class="container" v-if="user">
    <div class="row">
        <h4 class="header col s12">Administrador del Portal</h4>
    </div>
    <div class="row">
        <div v-show="user && !$g.busy" class="col s12">
            <h5>Consultas: {{visits}}</h5> <a href="#" @click.prevent="clearCounter">Limpiar</a>
            <h5>Mensajes: {{ data.length }}</h5>
            <table class="striped" v-show="data.length > 0">
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Email</th>
                        <th>Nombre</th>
                        <th>Empresa</th>
                        <th>Mensaje</th>
                        <th width="10px"></th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="item in data" :key="item.Key">
                        <td>{{item.FechaFmt}}</td>
                        <td>{{item.Email}}</td>
                        <td>{{item.Nombre}}</td>
                        <td>{{item.Empresa}}</td>
                        <td>{{item.Mensaje}}</td>
                        <td><a href="#" @click.prevent="deleteEmail(item.Key)">Eliminar</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>

<script>
import {
    Base
} from '../../base'
import {
    dbPortal,
    child,
    get,
    remove
} from "../../firebase";

export default {
    mixins: [Base],
    data() {
        return {
            data: [],
            visits: 0
        }
    },
    methods: {
        toArray(snapshot) {
            var arr = [];
            snapshot.forEach((childSnapshot) => {
                arr.push(childSnapshot);
            });
            return arr;
        },
        async clearCounter() {
            if (confirm('Desea limpiar el contador?') == false)
                return;
            await remove(child(dbPortal, 'Visitas'));
            this.visits = 0;
        },
        async deleteEmail(key) {
            if (confirm('Desea eliminar el correo?') == false) return;
            await remove(child(dbPortal, 'Mensajes/' + key));
            this.listMessages();
        },
        async listMessages() {
            var snapshot = await get(child(dbPortal, 'Visitas'));
            var val = snapshot.val() || 0;
            this.visits = val;
            snapshot = await get(child(dbPortal, 'Mensajes'));
            this.data = [];
            var arr = this.toArray(snapshot);
            for (var childSnapshot of arr) {
                var data = childSnapshot.val();
                var key = childSnapshot.key;
                this.data.push({
                    Key: key,
                    Fecha: new Date(data.Fecha),
                    FechaFmt: this.formatDateTime(new Date(data.Fecha)),
                    Email: data.Email,
                    Nombre: data.Nombre,
                    Empresa: data.Empresa,
                    Mensaje: data.Mensaje
                });
            }
            this.data.sort((b, a) => a.Fecha - b.Fecha);
        },
        async onAuth() {
            this.$g.busy = true;
            await this.listMessages();
            this.$g.busy = false;
        }
    },
    mounted() {
        this.$g.busy = false;
    }
};
</script>

<style scoped>
</style>
