<template>
<div class="row">
    <form class="col s12 m12" v-if="data">
        <div class="row">
            <h5 class="col s12 header">Datos del contacto</h5>
        </div>
        <div class="row">
            <div class="input-field col m12 s12">
                <input placeholder="Email" id="txtEmail" type="text" v-model="data.Email" readonly>
                <label for="txtEmail">Email</label>
            </div>
        </div>
        <div class="row">
            <div class="input-field col m12 s12">
                <input placeholder="Nombre" id="txtName" type="text" class="validate" v-model="data.Name">
                <label for="txtName">Nombre</label>
            </div>
        </div>
        <div class="row">
            <div class="input-field col m12 s12">
                <input placeholder="Teléfono" id="txtPhone" type="text" class="validate" v-model="data.Phone">
                <label for="txtPhone">Teléfono</label>
            </div>
        </div>
        <div class="row">
            <h5 class="col s12 s12 header">Dirección de entrega</h5>
        </div>
        <div class="row" v-if="data.Address">
            <div class="input-field col m12 s12">
                <input placeholder="Calle" id="txtCalle" type="text" class="validate" v-model="data.Address.Calle">
                <label for="txtCalle">Calle</label>
            </div>
        </div>
        <div class="row" v-if="data.Address">
            <div class="input-field col m12 s12">
                <input placeholder="Número de casa o departamento" id="txtNumero" type="text" class="validate" v-model="data.Address.Numero">
                <label for="txtNumero">Número</label>
            </div>
        </div>
        <div class="row" v-if="data.Address">
            <div class="input-field col m12 s12">
                <input placeholder="Número de departamento (opcional)" id="txtDepto" type="text" class="validate" v-model="data.Address.Depto">
                <label for="txtDepto">Departamento</label>
            </div>
        </div>
        <div class="row" v-if="data.Address">
            <div class="input-field col m12 s12">
                <select v-model="data.Address.Region" @change="listarComunas">
                    <option value="" disabled selected>Seleccione región</option>
                    <option v-for="r in regiones" :key="r.Codigo" :value="r">{{ r.Nombre }}</option>
                </select>
                <label>Región</label>
            </div>
        </div>
        <div class="row" v-if="data.Address">
            <div class="input-field col m12 s12">
                <select v-model="data.Address.Comuna">
                    <option value="" disabled selected>Seleccione comuna</option>
                    <option v-for="r in comunasPorRegion" :key="r.Codigo" :value="r">{{ r.Nombre }}</option>
                </select>
                <label>Comuna</label>
            </div>
        </div>
    </form>
</div>
</template>

<script>
import M from 'materialize-css'
import tablaRegiones from '../assets/regiones.json'
import tablaComunas from '../assets/comunas.json'
var elems;

export default {
    data() {
        return {
            regiones: tablaRegiones,
            comunas: tablaComunas,
            comunasPorRegion: null
        }
    },
    computed: {
        data: {
            get() {
                return this.modelValue
            },
            set(newValue) {
                this.$emit('update:modelValue', newValue)
            },
        },
    },
    props: ['modelValue'],
    methods: {
        listarComunas() {
            if (!(this.data && this.data.Address)) return;
            this.comunasPorRegion = this.comunas.filter(x => x.Region == this.data.Address.Region.Codigo);
            this.comunasPorRegion.sort((x, y) => x.Nombre.localeCompare(y.Nombre));
            this.$nextTick(() => {
                M.FormSelect.init(elems);
            })
        },
        initForm() {
            this.$nextTick(() => {
                elems = document.querySelectorAll('select');
                M.FormSelect.init(elems);
                M.updateTextFields();
                this.listarComunas();
            });
        }
    }
};
</script>

<style scoped></style>
