<template>
<div class="container">
    <div class="row">
        <h4 class="header col m12 s12">Pago de productos</h4>
    </div>

    <div v-show="!$g.busy">
        <div class="row" v-show="!$g.busy">
            <div class="col m8 s12">
                <h5>Datos de la entrega <div class="btn btn-info btn-small right" @click.prevent="editProfile">Modificar</div>
                </h5>
                <div class="card-panel" v-html="formatProfile(info)"></div>
            </div>
            <div class="col m4 s12 right-align">
                <router-link to="/cart" class="waves-effect waves-light btn"><i class="material-icons left">shopping_cart</i>Volver al carro</router-link>
            </div>
        </div>

        <div class="row" v-show="!$g.busy">
            <div class="col m8 s12">
                <h5>Detalle de productos</h5>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Producto</th>
                            <th class="right-align">Cantidad</th>
                            <th class="right-align">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in data" :key="item.Key">
                            <td width="100%">{{ item.Product.Name }} <span class="badge" v-if="!item.Ignore && !item.Available">No disponible</span></td>
                            <td class="right-align">{{ item.Quantity }}</td>
                            <td class="right-align">{{ formatMoney(item.Total) }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colspan="2"></th>
                            <th class="right-align">{{ formatMoney(calcSum) }}</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div class="col m4 s12 right-align">
                <div id="wallet_container"></div>
                <div class="card-panel left-align">
                    (*) El costo de despacho incluye la tarifa de servicio de <a href="https://www.chilexpress.cl/" target="_blank">Chilexpress</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="modalInfo" class="modal modal-fixed-footer bottom-sheet1">
    <div class="modal-content">
        <h4>Información de entrega</h4>
        <ProfileEditor v-model="info" ref="proEditor" />
    </div>
    <div class="modal-footer">
        <a href="#!" class="modal-close waves-effect waves-light btn-flat">Cerrar</a>&nbsp;
        <a href="#!" class="waves-effect waves-light btn" @click.prevent="saveProfile"><i class="material-icons left">save</i>Guardar</a>&nbsp;
    </div>
</div>

<FooterView />
</template>

<script>
import {
    Base
} from '../base'
import {
    where
} from "../firebase";
import CartModel from '../models/CartModel'
import OrderModel from '../models/OrderModel'
import ProfileModel from '../models/ProfileModel'
import ProductModel from '../models/ProductModel'
import ProfileEditor from './ProfileEditorView.vue'

const STATUS = 'PENDIENTE';
var cart = new CartModel();
var orders = new OrderModel();
var profile = new ProfileModel();
var prods = new ProductModel();
var modalInfo;
const mp = new window.MercadoPago(process.env.VUE_APP_MERCADOPAGO_PUB_KEY);
const bricksBuilder = mp.bricks();

export default {
    mixins: [Base],
    data() {
        return {
            data: [],
            info: {},
            preference_id: null
        }
    },
    components: {
        ProfileEditor
    },
    computed: {
        isInfoValid() {
            return this.info &&
                (this.info.Email && this.info.Name && this.info.Phone) &&
                this.info.Address &&
                (this.info.Address.Calle && this.info.Address.Numero && this.info.Address.Comuna.Codigo && this.info.Address.Region.Codigo);
        },
    },
    methods: {
        async editProfile() {
            modalInfo.open();
        },
        async saveProfile() {
            var ok = this.isInfoValid;
            if (!ok) {
                this.showMessage('Faltan datos', 'Por favor ingrese todos los datos necesarios para el despacho');
                return;
            }
            modalInfo.close();
            this.$g.busy = true;
            await profile.set(this.user.uid, this.info);
            await this.generateOrder(STATUS);
            this.$g.busy = false;
        },
        async generateButton() {
            try {
                var items = [];
                this.data.forEach(item => {
                    items.push({
                        title: item.Product.Name,
                        quantity: item.Quantity,
                        unit_price: item.Product.Price,
                        currency_id: "CLP",
                    })
                });
                var payload = {
                    items: items,
                    back_urls: {
                        success: process.env.VUE_APP_WEBHOOK_SUCCESS,
                        failure: process.env.VUE_APP_WEBHOOK_FAILURE,
                        // "pending": process.env.VUE_APP_WEBHOOK_PENDING
                    },
                    // payer: {
                    //     name: this.info.Name,
                    //     email: this.info.Email,
                    //     address: {
                    //         street_name: this.info.Address.Calle,
                    //         street_number: this.info.Address.Numero
                    //     }
                    // }
                    auto_return: 'approved',
                    binary_mode: true
                };
                // console.log(payload);
                var res = await this.axios.post('https://api.mercadopago.com/checkout/preferences', payload, {
                    headers: {
                        'Authorization': `Bearer ${process.env.VUE_APP_MERCADOPAGO_ACCESS_TOKEN}`
                    }
                });
                this.preference_id = res.data.id;

            } catch (err) {
                console.error(err);
                alert(err);
            }
        },
        async generateOrder(status) {
            var order = orders.create();
            order.Key = order.id;
            var arr = await orders.list(this.user.uid, where('Status', '==', status));
            if (arr.length > 0) {
                order = arr[0];
                orders.clearItems(this.user.uid, order.Key);
            }
            this.data = await cart.list();
            this.data.push({
                Key: process.env.VUE_APP_COSTO_DESPACHO,
                Product: {},
                Quantity: 1,
                Ignore: true
            });
            arr = [];
            var cart_completed = true;
            for (var item of this.data) {
                var prod = await prods.get(item.Key);
                if (prod) {
                    item.Product.Name = prod.Name;
                    item.Product.Price = prod.Price;
                    item.Total = item.Product.Price * item.Quantity;
                    await orders.addItem(this.user.uid, order.Key, item.Key, item);
                    item.Ignore = item.Ignore ? item.Ignore : false;
                    item.Available = prod.Available;
                    arr.push(item);
                    if (!item.Ignore && !item.Available) {
                        cart_completed = false;
                    }
                }
            }
            if (!cart_completed) {
                this.showMessage('Productos faltantes', 'Hay productos que ya no existen por lo que debe eliminarlos del carro. Por favor vuelva al carro, revíselo y vuela a intentar la compra');
                return null;
            }
            this.data = arr;
            await this.generateButton();
            var date = new Date();
            var data = {
                Profile: this.info,
                History: [{
                    Status: status,
                    Date: date
                }],
                Status: status,
                Date: date,
                Total: this.calcSum,
                PreferenceID: this.preference_id
            }
            orders.set(this.user.uid, order.Key, data);
            return order;
        },
        async onAuth() {
            this.info = await profile.get(this.user);
            var res = (await this.generateOrder(STATUS));
            if (res) {
                if (window.paymentBrickController) {
                    window.paymentBrickController.unmount();
                }
                window.paymentBrickController = await bricksBuilder.create("wallet", "wallet_container", {
                    initialization: {
                        preferenceId: this.preference_id,
                        // redirectMode: "modal"
                    }
                });
            }
            if (!this.isInfoValid) {
                this.$nextTick(() => {
                    modalInfo.open();
                })
            }
            this.$g.busy = false;
        }
    },
    mounted() {
        modalInfo = this.initModal('modalInfo', {
            onOpenEnd: () => {
                if (this.$refs.proEditor) {
                    this.$refs.proEditor.initForm();
                }
            }
        });
    },
    unmount() {
        if (window.paymentBrickController) {
            window.paymentBrickController.unmount();
        }
    }
}
</script>

<style scoped>
</style>
