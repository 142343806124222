import { initializeApp } from 'firebase/app'
import { getDatabase, ref, child, get, update, push, query, remove } from 'firebase/database'
import { getAuth, signInWithEmailAndPassword, signOut, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFirestore, collection, collectionGroup, where, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc, onSnapshot, orderBy } from 'firebase/firestore';
import { getStorage, ref as sref, getDownloadURL, uploadBytesResumable, listAll, deleteObject } from "firebase/storage";

export const firebaseApp = initializeApp({
	apiKey: "AIzaSyA0N_XD39divml2JXlLia2cnYtURgq4bQs",
	authDomain: "binatrix-a66a8.firebaseapp.com",
	databaseURL: "https://binatrix-a66a8.firebaseio.com",
	projectId: "binatrix-a66a8",
	storageBucket: "binatrix-a66a8.appspot.com",
	messagingSenderId: "202077607185"
});

const db = getDatabase(firebaseApp);
const fs = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

export const dbPortal = ref(db, 'Portal')
export const dbProducts = collection(fs, 'Products');
export const dbOrders = collection(fs, 'Orders');
export const dbOrdersAdmin = collectionGroup(fs, 'Orders');
export const dbProfile = collection(fs, 'Profile');
export const dbCart = collection(fs, 'Cart');
export const fsProducts = sref(storage, 'Products');

export {
	child, get, update, push, query, remove, doc, ref,
	getDocs, getDoc, setDoc, addDoc, deleteDoc, onSnapshot, where, orderBy,
	collection, collectionGroup, getAuth,
	signInWithEmailAndPassword, signOut, GoogleAuthProvider, signInWithPopup,
	getDownloadURL, sref, uploadBytesResumable, listAll, deleteObject
};
