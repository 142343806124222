,<template>
<div class="container" v-if="!$g.busy">
    <div v-if="error == 0">
        <h4 v-if="success" class="header">Pago exitoso</h4>
        <h4 v-else class="header">Error en el pago</h4>
        <div v-if="success">
            <div class="card-panel">Se ha completado el pago de los productos. Revise el detalle de la operación a continuación</div>
            <div class="row">
                <div class="col m8">
                    <table class="table">
                        <tr>
                            <td>Número de orden</td>
                            <td>{{order.Key}}</td>
                        </tr>
                        <tr>
                            <td>Número de operación MercadoPago</td>
                            <td>{{order.PaymentID}}</td>
                        </tr>
                        <tr>
                            <td>Estado</td>
                            <td>{{order.Status}}</td>
                        </tr>
                        <tr>
                            <td>Monto</td>
                            <td>{{formatMoney(order.Total)}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <h4 v-if="error == 1" class="header">La órden de compra no existe</h4>
    <h4 v-if="error == 2" class="header">La órden de compra no se encuentra pendiente de pago</h4>
    <router-link v-if="error != 1" :to="'/order/' + order.Key" class="waves-effect waves-light btn"><i class="material-icons left">visibility</i>Seguimiento de orden</router-link>
</div>
<FooterView />
</template>

<script>
import {
    Base
} from '../base'
import OrderModel from '../models/OrderModel'
import CartModel from '../models/CartModel'

var orders = new OrderModel();
var cart = new CartModel();

export default {
    mixins: [Base],
    data() {
        return {
            q: {},
            success: false,
            cancel: false,
            order: {},
            error: null
        }
    },
    methods: {
        async onAuth() {
            if (!this.cancel) {
                var res = await orders.confirm(this.user.uid, this.q.preference_id, this.q.payment_id);
                this.error = res.Error;
                this.order = res;
                if (res.Error == 0) {
                    cart.clear();
                }
            }
            this.$g.busy = false;
        }
    },
    created() {
        this.q = this.$route.query;
        if (this.q.status == "null") {
            this.cancel = true;
            this.$router.push('/cart');
            return;
        }
        this.success = this.q.status == 'approved';
        // console.log(this.q.collection_id);
        // console.log(this.q.collection_status);
        // console.log(this.q.payment_id);
        // console.log(this.q.status);
        // console.log(this.q.external_reference);
        // console.log(this.q.payment_type);
        // console.log(this.q.merchant_order_id);
        // console.log(this.q.preference_id);
        // console.log(this.q.site_id);
        // console.log(this.q.processing_mode);
        // console.log(this.q.merchant_account_id);
        //http://localhost:8080/feedback?preference_id=241776590-66c480db-1ad1-4cfc-8688-792cc4d4de02&status=approved&payment_id=33333
    }
};
</script>

<style scoped>
    </style>
