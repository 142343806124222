<template>
<nav>
    <div class="container-fluid">
        <div class="nav-wrapper">
            <a href="/" class="brand-logo">&nbsp;BINATRIX</a>
            <a href="#" data-target="mobile-demo" class="sidenav-trigger"><i class="material-icons">menu</i></a>
            <ul id="nav-mobile" class="right hide-on-med-and-down">
                <li v-show="$g.busy">
                    <div class="preloader-wrapper small active">
                        <div class="spinner-layer spinner-yellow-only">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div>
                            <div class="gap-patch">
                                <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li><a class="dropdown-trigger" href="#!" data-target="dropdown2">Tutoriales<i class="material-icons right">arrow_drop_down</i></a></li>
                <li :class="{ 'active': this.$route.path === '/store'}">
                    <router-link to="/store" class="tooltipped" data-position="bottom" data-tooltip="Productos"><i class="material-icons">apps</i></router-link>
                </li>
                <li :class="{ 'active': this.$route.path === '/cart'}" title="Carro de compras">
                    <router-link to="/cart" class="tooltipped" data-position="bottom" data-tooltip="Carro de compra"><i class="material-icons">shopping_cart</i></router-link>
                </li>
                <!-- <li v-show="user"><a class="dropdown-trigger" href="#!" data-target="dropdown1">{{user ? user.email: null}}<i class="material-icons right">arrow_drop_down</i></a></li> -->
                <li v-show="user"><a class="dropdown-trigger" href="#!" data-target="dropdown1">{{user ? user.displayName: null}}<img :src="user ? user.photoURL : null" align="left" class="responsive-img" style="height:64px;margin-right:10px"><i class="material-icons right">arrow_drop_down</i></a></li>
                <li v-show="!user"><a href="#" @click.prevent="showLogin" class="tooltipped" data-position="bottom" data-tooltip="Ingresar a su cuenta">Acceder</a></li>
            </ul>
        </div>
        <ul id="dropdown1" class="dropdown-content">
            <li :class="{ 'active': this.$route.path === '/profile'}">
                <router-link to="/profile"><i class="material-icons">assignment_ind</i>Mi Perfil</router-link>
            </li>
            <li v-if="user" :class="{ 'active': this.$route.path === '/orders'}">
                <router-link to="/orders"><i class="material-icons">description</i>Mis Pedidos</router-link>
            </li>
            <li v-if="user && admin" class="divider"></li>
            <li v-if="user && admin" :class="{ 'active': this.$route.path === '/admin/portal'}">
                <router-link to="/admin/portal"><i class="material-icons">home</i>Portal</router-link>
            </li>
            <li v-if="user && admin" :class="{ 'active': this.$route.path === '/admin/products'}">
                <router-link to="/admin/products"><i class="material-icons">apps</i>Productos</router-link>
            </li>
            <li v-if="user && admin" :class="{ 'active': this.$route.path === '/admin/orders'}">
                <router-link to="/admin/orders"><i class="material-icons">description</i>Ordenes</router-link>
            </li>
            <li class="divider"></li>
            <li v-if="user"><a href="#" @click.prevent="logout"><i class="material-icons">exit_to_app</i>Salir</a></li>
        </ul>
        <ul id="dropdown2" class="dropdown-content">
            <li :class="{ 'active': this.$route.path === '/tutoriales/ESPBASIC'}">
                <router-link to="/tutoriales/ESPBASIC">ESP-BASIC</router-link>
            </li>
        </ul>
    </div>
</nav>

<ul class="sidenav" id="mobile-demo">
    <li v-if="user">
        <div class="user-view white-text">
            <div class="background">
                <img src="img/face.jpg">
            </div>
            <a href="#user"><img class="circle" :src="user ? user.photoURL : null"></a>
            <a href="#name"><span class="white-text name">{{user ? user.displayName : null}}</span></a>
            <a href="#email"><span class="white-text email">{{user ? user.email : null}}</span></a>
        </div>
    </li>
    <li :class="{ 'active': this.$route.path === '/store'}">
        <router-link to="/store"><i class="material-icons">apps</i>Productos</router-link>
    </li>
    <li :class="{ 'active': this.$route.path === '/cart'}">
        <router-link to="/cart"><i class="material-icons">shopping_cart</i>Carro de compras</router-link>
    </li>
    <li v-if="user" class="divider"></li>
    <li v-if="user" :class="{ 'active': this.$route.path === '/profile'}">
        <router-link to="/profile"><i class="material-icons">assignment_ind</i>Mi Perfil</router-link>
    </li>
    <li v-if="user" :class="{ 'active': this.$route.path === '/orders'}">
        <router-link to="/orders"><i class="material-icons">description</i>Mis Pedidos</router-link>
    </li>
    <li v-if="user && admin" class="divider"></li>
    <li v-if="user && admin" :class="{ 'active': this.$route.path === '/admin/portal'}">
        <router-link to="/admin/portal"><i class="material-icons">home</i>Portal</router-link>
    </li>
    <li v-if="user && admin" :class="{ 'active': this.$route.path === '/admin/products'}">
        <router-link to="/admin/products"><i class="material-icons">apps</i>Productos</router-link>
    </li>
    <li v-if="user && admin" :class="{ 'active': this.$route.path === '/admin/orders'}">
        <router-link to="/admin/orders"><i class="material-icons">description</i>Ordenes</router-link>
    </li>
    <li class="divider"></li>
    <li v-if="user"><a href="#" @click.prevent="logout"><i class="material-icons">exit_to_app</i>Salir</a></li>
    <li v-show="!user"><a href="#" @click.prevent="showLogin"><i class="material-icons">vpn_key</i>Acceder</a></li>
</ul>

<div id="modal1" class="modal">
    <div class="modal-content">
        <h4>{{$g.message.title}}</h4>
        <p v-html="$g.message.message"></p>
    </div>
    <div class="modal-footer">
        <a href="#!" class="modal-close waves-effect waves-green btn-flat">Cerrar</a>
    </div>
</div>

<div id="modal2" class="modal">
    <div class="modal-content">
        <h4>Access</h4>
        <div class="row">
            <div class="input-field col s12">
                <input id="txtUsername" type="email" class="validate" v-model="form.username">
                <label for="txtUsername">Email</label>
            </div>
        </div>
        <div class="row">
            <div class="input-field col s12">
                <input type="password" class="validate" v-model="form.password">
                <label for="txtPassword">Password</label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <a href="#!" class="waves-effect waves-green btn-flat" @click.prevent="login">Login</a>
        <a href="#!" class="modal-close waves-effect waves-green btn-flat">Close</a>
    </div>
</div>
</template>

<script>
import {
    Base,
    auth
} from '../base'
import {
    signOut
} from "../firebase";
import M from 'materialize-css'
// var modal2;

export default {
    mixins: [Base],
    data() {
        return {
            form: {}
        }
    },
    methods: {
        showLogin() {
            // var elem = document.getElementById('modal2');
            // modal2 = M.Modal.init(elem, {
            //     onOpenEnd: () => {
            //         document.getElementById('txtUsername').focus();
            //     }
            // });
            // modal2.open();
            this.login();
        },
        async logout() {
            if (confirm('Desea finalizar la sesión?') == false)
                return;
            await signOut(auth);
            this.$router.push('/');
        }
    },
    mounted() {
        var elems = document.querySelectorAll('.dropdown-trigger');
        M.Dropdown.init(elems);
        elems = document.querySelectorAll('.tooltipped');
        M.Tooltip.init(elems);
    }
}
</script>

<style>
.preloader-wrapper {
    top: 10px;
    margin-right: 20px;
}

.sidenav-overlay {
    z-index: 200;
}

.sidenav .user-view {
    height: 200px;
}
</style>
