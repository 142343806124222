import { createApp, reactive } from 'vue'
import { VueFire, VueFireAuth } from 'vuefire'
import App from './App.vue'
import Routes from './routes';
import { createWebHistory, createRouter } from "vue-router";
import { firebaseApp, getAuth } from './firebase';
import 'materialize-css/dist/css/materialize.min.css'
import 'material-design-icons/iconfont/material-icons.css'
import axios from 'axios'
import VueAxios from 'vue-axios'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: Routes,
  linkActiveClass: "active",
  linkExactActiveClass: "active"
});

router.beforeEach((to, from, next) => {
  const currentUser = getAuth().currentUser;
  var admin = currentUser && process.env.VUE_APP_USER_ADMIN == currentUser.email
  if (to.meta.adminRequired === true && !admin) {
      router.push({
        name: 'Unauthorized'
      })
  }
  return next();
});

const app = createApp(App);
app.use(router);
app.use(VueFire, {
  firebaseApp,
  modules: [
    VueFireAuth()
  ]
})
app.use(VueAxios, axios)
app.config.globalProperties.$g = reactive({
  busy: false,
  message: { title: null, message: null }
});
app.mount('#app')