<template>
    <div class="container" v-if="user">
        <h4 class="header">Administrador de Productos</h4>
        <div class="right-align" v-if="data && data[0]">
            <a href="#!" class="waves-effect waves-light btn" @click.prevent="editProduct(null)"><i
                    class="material-icons left">add</i>Agregar</a>&nbsp;
        </div>
        <table class="table" v-if="data && data[0]">
            <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Disp</th>
                    <th class="right-align">Precio</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in data" :key="item.Key" :class="{'grey lighten-2': !item.Available}">
                    <td width="100%"><a href="#" @click.prevent="editProduct(item.Key)">{{ item.Name }}</a></td>
                    <td>{{ item.Available ? 'Si' : 'No' }}</td>
                    <td class="right-align">{{ formatMoney(item.Price) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div id="modalEdit" class="modal modal-fixed-footer bottom-sheet1">
        <div class="modal-content">
            <h4>Editor de producto</h4>
            <form class="col s6">
                <div class="row">
                    <div class="col s12 right-align">
                        <label>
                            <input type="checkbox" class="filled-in" checked="checked" v-model="item.Available" />
                            <span>Disponible</span>
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s12">
                        <input placeholder="Nombre" id="txtName" type="text" v-model="item.Name">
                        <label for="txtName">Nombre</label>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s12">
                        <input placeholder="Precio" id="txtPrice" type="number" v-model.number="item.Price">
                        <label for="txtPrice">Precio</label>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s12">
                        <textarea placeholder="Descripción" class="materialize-textarea" id="txtDescription" type="text"
                            v-model="item.Description"></textarea>
                        <label for="txtDescription">Descripción</label>
                    </div>
                </div>
                <div class="row" v-if="item.Key">
                    <div class="file-field input-field col s12">
                        <div class="btn">
                            <span>Imágenes</span>
                            <input type="file" id="uploadFile" multiple @change="fileChange">
                        </div>
                        <div class="file-path-wrapper">
                            <input class="file-path validate" type="text">
                        </div>
                    </div>
                    <div class="col s12">
                        <a v-if="!hasFiles" class="waves-effect waves-light btn-small" disabled><i
                                class="material-icons left">add</i>Agregar</a>
                        <a v-else class="waves-effect waves-light btn-small" @click.prevent="addImage"><i
                                class="material-icons left">add</i>Agregar</a>
                        {{ progress }}
                        <a v-if="!image" class="right waves-effect waves-light btn-small red lighten-2" disabled><i
                                class="material-icons left">delete</i>Eliminar</a>
                        <a v-else class="right waves-effect waves-light btn-small red lighten-2"
                            @click.prevent="deleteImage"><i class="material-icons left">delete</i>Eliminar</a>
                    </div>
                </div>
                <div v-if="item.Images" class="row" style="overflow-x: auto;">
                    <div class="col s3 center-align" v-for="img in item.Images" :key="img.Name">
                        <a href="#" @click.prevent="selectImage(img)"><img :src="img.Url" class="img-responsive"
                                style="height: 100px"></a>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-light btn-flat">Cerrar</a>&nbsp;
            <a v-if="item.Key" href="#!" class="waves-effect waves-light btn red lighten-2"
                @click.prevent="deleteProduct"><i class="material-icons left">delete</i>Eliminar</a>&nbsp;
            <a href="#!" class="waves-effect waves-light btn" @click.prevent="saveProduct"><i
                    class="material-icons left">save</i>Guardar</a>&nbsp;
        </div>
    </div>
</template>

<script>
import {
    Base
} from '../../base'
import ProductModel from '../../models/ProductModel'

var prods = new ProductModel();
var modalEdit;

export default {
    mixins: [Base],
    data() {
        return {
            data: [],
            item: {},
            image: null,
            progress: null,
            hasFiles: null
        }
    },
    methods: {
        fileChange() {
            this.hasFiles = document.getElementById('uploadFile').files && document.getElementById('uploadFile').files.length > 0;
        },
        clearFileInput() {
            if (this.item.Key) {
                document.getElementById('uploadFile').files = null;
                document.getElementsByClassName('file-path')[0].value = "";
            }
            this.hasFiles = false;
        },
        async selectImage(img) {
            this.image = img;
        },
        async deleteImage() {
            this.progress = 'Eliminando...';
            await prods.deleteImage(this.image.Path);
            this.item.Images = (await prods.loadImages(this.item.Key)).items;
            this.image = null;
            this.progress = null;
        },
        async addImage() {
            var self = this;
            const files = document.getElementById('uploadFile').files;
            if (!files || files.length == 0) {
                alert('Debe seleccionar una imagen');
                return;
            }
            this.progress = 'Subiendo imagenes...';
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                prods.addImage(file, this.item.Key, file.name, () => {
                    // var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    // self.progress = `Subiendo ${progress}`;
                }, (error) => {
                    alert(error);
                }, async () => {
                    self.item.Images = (await prods.loadImages(self.item.Key)).items;
                    self.progress = null;
                    self.clearFileInput();
                });
            }
        },
        async saveProduct() {
            await prods.set(this.item);
            this.toast('Cambios guardados');
        },
        async deleteProduct() {
            if (confirm('Desea eliminar el producto') == false)
                return;
            await prods.delete(this.item.Key);
            this.toast('Producto eliminado');
            modalEdit.close();
        },
        async editProduct(key) {
            this.$g.busy = true;
            this.item = {
                Available: true,
                Images: []
            };
            this.image = null;
            if (key) {
                this.item = await prods.get(key);
                this.item.Images = (await prods.loadImages(key)).items;
            }
            modalEdit.open();
            this.$g.busy = false;
        },
        async onAuth() {
            prods.subscribe(data => {
                this.data = data;
                this.$g.busy = false;
            })
        }
    },
    async mounted() {
        modalEdit = this.initModal('modalEdit', {
            onOpenEnd: () => {
                this.updateTextFields();
                this.resizeTextArea('txtDescription');
                this.clearFileInput();
                document.getElementById('txtName').focus();
            }
        });
        this.$g.busy = false;
    },
    unmounted() {
        if (!this.user) return;
        prods.unsubscribe();
    }
};
</script>

<style scoped>.card-image {
    height: 200px;
    overflow: hidden;
}</style>
