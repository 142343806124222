<template>
<div class="container">
    <div class="row">
        <div class="col s12">
            <h4 class="header">Catálogo de productos</h4>
        </div>
    </div>
    <div class="row" v-show="!$g.busy">
        <div class="col s12 m4" v-for="item in data" :key="item.Key">
            <div class="card medium hoverable">
                <div class="card-image">
                    <router-link :to="'/product/' + item.Key">
                        <img v-if="item.Images && item.Images[0]" :src="item.Images[0].Url">
                    </router-link>
                </div>
                <div class="card-content">
                    <span class="card-title">{{item.Name}}</span>
                    <!-- <p>{{item.Description}}</p> -->
                    <h5>{{ formatMoney(item.Price) }}</h5>
                </div>
                <div class="card-action">
                    <a v-if="item.Available" href="#" @click.prevent="buy(item)">Comprar</a>
                    <a v-else href="#" class="disabled">Agotado</a>
                    <router-link :to="'/product/' + item.Key">Detalles</router-link>
                </div>
            </div>
        </div>
    </div>
</div>
<FooterView />
</template>

<script>
import {
    Base
} from '../base'
import {
    where
} from "../firebase";

import ProductModel from '../models/ProductModel'

var prods = new ProductModel();

export default {
    mixins: [Base],
    data() {
        return {
            data: []
        }
    },
    methods: {
        async listProducts() {
            this.data = await prods.list(where('Available', '==', true), doc => {
                this.data.find(x => x.Key == doc.key).Images = doc.items;
            });
        }
    },
    async mounted() {
        await this.listProducts();
        this.$g.busy = false;
    }
};
</script>

<style scoped>
.card-image img {
    height: 200px;
    object-fit: cover;
}
</style>
