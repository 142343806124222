import { AES, enc } from 'crypto-js';

export default class Settings {

    getSetting(tag, def) {
        if (window.localStorage) {
            var val = window.localStorage.getItem(tag);
            if (val != null) {
                var dec = AES.decrypt(val, process.env.VUE_APP_CRYPTO_KEY);
                return JSON.parse(dec.toString(enc.Utf8));
            }
        }
        return def;
    }

    setSetting(tag, val) {
        if (window.localStorage) {
            var buf = JSON.stringify(val);
            var enc = AES.encrypt(buf, process.env.VUE_APP_CRYPTO_KEY);
            window.localStorage.setItem(tag, enc.toString());
        }
    }
}
