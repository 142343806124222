<template>
<div class="container">
    <h4 class="header">Carro de compras</h4>
    <div v-if="!$g.busy" class="row">
        <h6 v-if="!(data && data[0])" class="card-panel">El carro de compras está vacío</h6>
        <div v-else>
            <div class="col m8">
                <h6 class="card-panel">Hay {{ data.length }} productos en el carro de compras</h6>
                <!-- <div class="center-align">
                <a class="waves-effect waves-light btn" @click.prevent="pay"><i class="material-icons left">payment</i>Pagar</a>
            </div> -->
                <table class="table">
                    <thead>
                        <tr>
                            <th>Producto</th>
                            <th class="right-align">Cantidad</th>
                            <th class="right-align">Total</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in data" :key="item.Key">
                            <td width="100%">
                                <router-link :to="'/product/' + item.Key">{{ item.Product.Name }}</router-link>
                            </td>
                            <td class="right-align"><input type="number" class="right-align" min="1" max="1000000" v-model="item.Quantity" @change="updateItem(item)"></td>
                            <td class="right-align">{{ formatMoney(item.Total) }}</td>
                            <td><a href="#" @click.prevent="deleteItem(item)"><i class="material-icons red-text text-lighten-2">delete_forever</i></a></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colspan="2"></th>
                            <th class="right-align">{{ formatMoney(calcSum) }}</th>
                        </tr>
                    </tfoot>
                </table>
                <br>
                <div class="right-align">
                    <a href="#!" class="waves-effect waves-light btn red lighten-2 left" @click.prevent="emptyCart"><i class="material-icons left">delete</i>Vaciar el carro</a>&nbsp;
                    <a href="#!" class="waves-effect waves-light btn" @click.prevent="checkOut"><i class="material-icons left">payment</i>Proceder al pago</a>&nbsp;
                    <!-- <router-link to="/payment" class="waves-effect waves-light btn"><i class="material-icons left">payment</i>Proceder al pago</router-link> -->
                </div>
            </div>
            <div class="col m4">
                <div class="card-panel">
                    <p>
                        El proceso se realiza mediante la plataforma de <b><a href="https://www.mercadopago.cl/" target="_blank">MercadoPago</a></b> la cual permite realizar pagos con tarjeta de
                        crédito y/o débido en línea.
                    </p>
                    <p>
                        Para esta operación el sistema lo redireccionará hacia los servidores de MercadoPago a través de
                        canales seguros.
                    </p>
                    <p>
                        Nuestro sitio no tiene acceso a la información de las tarjetas utilizadas.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="modalLogin" class="modal">
    <div class="modal-content">
        <h4>Acceso requerido</h4>
        <p>Por favor ingresa a tu cuenta para poder comprar.</p>
    </div>
    <div class="modal-footer">
        <a href="#!" class="modal-close waves-effect waves-green btn-flat">Cerrar</a>
        <a href="#!" class="waves-effect waves-light btn" @click.prevent="showLogin"><i class="material-icons left">vpn_key</i>Acceder</a>&nbsp;
    </div>
</div>

<FooterView />
</template>

<script>
import {
    Base
} from '../base'
import CartModel from '../models/CartModel'
import M from 'materialize-css'

var cart = new CartModel();
var modalLogin;

export default {
    mixins: [Base],
    data() {
        return {
            data: []
        }
    },
    methods: {
        showLogin() {
            modalLogin.close();
            this.$nextTick(() => {
                this.login(() => {
                    this.$router.push('/payment');
                });
            });
        },
        checkOut() {
            if (!this.user) {
                modalLogin.open();
                // this.showMessage('Acceso requerido', 'Por favor ingresa a tu cuenta para poder comprar. Para ello selecciona el menú "Acceder" en la barra superior.');
                return;
            }
            this.$router.push('/payment');
        },
        emptyCart() {
            if (confirm('Desea vaciar el carro de compras?') == false)
                return;
            this.data = cart.clear();
            this.toast('Carro vaciado');
        },
        updateItem(item) {
            this.data = cart.update(item);
        },
        deleteItem(item) {
            if (confirm('Desea eliminar el producto del carro?') == false)
                return;
            this.data = cart.delete(item);
            this.toast('Producto eliminado del carro');
        }
    },
    mounted() {
        var elem = document.getElementById('modalLogin');
        modalLogin = M.Modal.init(elem);
        try {
            this.data = cart.list();
        } catch (e) {
            this.showMessage('Error al leer carro', e);
            this.data = cart.clear();
        }
        this.$g.busy = false;
    }
};
</script>

<style scoped></style>
