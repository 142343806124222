export default class BaseModel {

    toArray(snapshot) {
        var arr = [];
        snapshot.forEach((childSnapshot) => {
            arr.push(childSnapshot);
        });
        return arr;
    }

    toData(snapshot) {
        var data = snapshot.data();
        data.Key = snapshot.id;
        data.Ref = snapshot.ref;
        if (data.Date) {
            data.Date = data.Date.toDate();
        }
        return data;
    }

    deleteKeys(item) {
        var item2 = Object.assign({}, item);
        delete item2['Key'];
        delete item2['Ref'];
        return item2;
    }
}

