<template>
<div class="container">
    <div class="row">
        <h4 class="header col s12">Ficha de producto</h4>
    </div>
    <div class="row" v-if="!$g.busy">
        <div class="col m7" v-if="data.Images">
            <img class="responsive-img" :src="image">
            <div class="row" style="overflow-x: auto;">
                <div class="col m3 center-align" v-for="img in data.Images" :key="img.Name">
                    <a href="#" @click.prevent="selectImage(img)"><img :src="img.Url" class="img-responsive" style="height: 70px"></a>
                </div>
            </div>
        </div>
        <div class="col m5">
            <h5>{{ data.Name }}</h5>
            <h4>{{ formatMoney(data.Price) }}</h4>
            <br>
            <a v-if="data.Available" class="waves-effect waves-light btn" @click.prevent="buy(data)"><i class="material-icons left">add_shopping_cart</i>Comprar</a>
            <div v-else class="disabled btn">Producto Agotado</div>
        </div>
    </div>
    <div class="row" v-if="!$g.busy">
        <div class="col s12">
            <h5>Descripción del producto</h5>
            <div v-html="data.Description"></div>
        </div>
    </div>
</div>
<FooterView />
</template>

<script>
import {
    Base
} from '../base'
import ProductModel from '../models/ProductModel'

var prods = new ProductModel();

export default {
    mixins: [Base],
    data() {
        return {
            data: {},
            image: null
        }
    },
    methods: {
        selectImage(img) {
            this.image = img.Url;
        },
        async getProduct(id) {
            this.data = await prods.get(id, (doc) => {
                this.data.Images = doc.items;
                this.image = this.data.Images[0].Url;
            });
            this.$g.busy = false;
        }
    },
    mounted() {
        var id = this.$route.params.id;
        this.getProduct(id);
    }
};
</script>

<style scoped>
</style>
