import {
    dbProfile,
    deleteDoc,
    getDoc,
    setDoc,
    doc
} from "../firebase";

import BaseModel from './BaseModel'

export default class ProfileModel extends BaseModel {
    async get(user) {
        var snapshot = await getDoc(doc(dbProfile, user.uid));
        var data = snapshot.data();
        if (!data) {
            data = {
                Name: user.displayName,
                Address: {
                    Region: { Codigo: '' },
                    Comuna: { Codigo: '' }
                }
            }
        }
        data.Email = user.email;
        return data;
    }

    async set(uid, data) {
        await setDoc(doc(dbProfile, uid), data);
    }

    async delete(uid) {
        await deleteDoc(doc(dbProfile, uid));
    }
}

