import {
    dbOrders,
    dbOrdersAdmin,
    getDocs,
    getDoc,
    setDoc,
    deleteDoc,
    doc,
    collection,
    query,
    where,
    orderBy,
    onSnapshot
} from "../firebase";
import BaseModel from './BaseModel'

export const STATUS1 = 'PENDIENTE';
export const STATUS2 = 'PAGADO';
export const STATUS3 = 'DESPACHADO';
export const STATUS4 = 'ENTREGADO';
export const STATUS5 = 'ANULADO';

export default class OrderModel extends BaseModel {
    create() {
        return doc(dbOrders);
    }

    subscribeAdmin(filter, onData) {
        this.unsubscribe = onSnapshot(query(dbOrdersAdmin, filter), querySnapshot => {
            var res = [];
            querySnapshot.forEach(childSnapshot => {
                var data = this.toData(childSnapshot);
                res.push(data);
            });
            res.sort((x, y) => x.Date - y.Date).reverse();
            onData(res);
        });
    }

    unsuscribe() {
        if (!this.unsubscribe) return;
        this.unsubscribe();
    }

    // async listAdmin(filter) {
    //     var snapshot = await getDocs(query(dbOrdersAdmin, filter));
    //     var arr = this.toArray(snapshot);
    //     var res = [];
    //     for (var childSnapshot of arr) {
    //         var data = this.toData(childSnapshot);
    //         res.push(data);
    //     }
    //     return res;
    // }

    async list(uid, filter = null) {
        var q;
        if (filter) {
            q = query(collection(dbOrders, uid, 'Orders'), filter, orderBy("Status"), orderBy("Date", "desc"));
        } else {
            q = query(collection(dbOrders, uid, 'Orders'), orderBy("Date", "desc"));
        }
        var res = [];
        var snapshot = await getDocs(q);
        var arr = this.toArray(snapshot);
        for (var childSnapshot of arr) {
            var data = this.toData(childSnapshot);
            res.push(data);
        }
        res.sort((x, y) => x.Date - y.Date).reverse();
        return res;
    }

    async getByRef(ref) {
        var snapshot = await getDoc(ref);
        if (!snapshot.exists())
            return null;
        var data = this.toData(snapshot);
        data.History.forEach(h => {
            h.Date = h.Date.toDate();
        })
        return data;
    }

    async get(uid, id) {
        var data = await this.getByRef(doc(dbOrders, uid, 'Orders', id));
        if (!data) return null;
        var snapshot = await getDocs(collection(dbOrders, uid, 'Orders', id, 'Items'));
        var arr = this.toArray(snapshot);
        data.Products = [];
        for (var item of arr) {
            var buf = this.toData(item);
            data.Products.push(buf);
        }
        return data;
    }

    async setByRef(ref, data) {
        await setDoc(ref, this.deleteKeys(data));
    }

    async set(uid, orderId, data) {
        await this.setByRef(doc(dbOrders, uid, 'Orders', orderId), data);
    }

    async addItem(uid, orderId, itemId, data) {
        await this.setByRef(doc(dbOrders, uid, 'Orders', orderId, 'Items', itemId), data);
    }

    async clearItems(uid, orderId) {
        var querySnapshot = await getDocs(collection(dbOrders, uid, 'Orders', orderId, 'Items'));
        var arr = this.toArray(querySnapshot);
        for (var item of arr) {
            await deleteDoc(item.ref);
        }
    }

    async confirm(uid, preference_id, payment_id) {
        var querySnapshot = await getDocs(query(collection(dbOrders, uid, 'Orders'), where('PreferenceID', '==', preference_id)));
        var arr = this.toArray(querySnapshot);
        if (arr.length == 0) {
            return {
                Error: 1
            }; // No existe
        }
        var date = new Date();
        for (var item of arr) {
            var data = this.toData(item);
            if (data.Status != STATUS1) {
                data.Error = 2; // Ya pagada
                return data;
            }
            data.History.push({
                Status: STATUS2,
                Date: date
            });
            data.Status = STATUS2;
            data.Date = date;
            data.PaymentID = payment_id;
            await this.setByRef(item.ref, data);
            data.Error = 0;
            return data;
        }
    }

    async clear(uid) {
        var snapshot = await getDocs(collection(dbOrders, uid, 'Orders'));
        var arr1 = this.toArray(snapshot);
        for (var item1 of arr1) {
            var snapshot2 = await getDocs(collection(dbOrders, uid, 'Orders', item1.id, 'Items'));
            var arr2 = this.toArray(snapshot2);
            for (var item2 of arr2) {
                await deleteDoc(item2.ref);
            }
            await deleteDoc(item1.ref);
        }
        await deleteDoc(doc(dbOrders, uid));
    }
}
