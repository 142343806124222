<template>
<div class="container">
    <h4 class="header">Tutorial ESP-BASIC</h4>
    <p>
        ESP-BASIC es un controlador ESP8266 que incorpora un intérprete BASIC en su firmware lo cual permite desarrollar aplicaciones utilizando sólo el navegador web. No hay necesidad de instalar nada adicional.
    </p>
    <h5>Activación</h5>
    <p>
        Los pasos requeridos para activarlo son:
    </p>
    <nl>
        <li>Conectar el cargador de 5V por el puerto Micro-USB</li>
        <li>Esperar que encienda</li>
        <li>Buscar en un computador la red WiFi "ESP-BASIC" que genera el dispositivo</li>
        <li>Conectarse a la red WiFi "ESP-BASIC" (no requiere contraseña)</li>
        <li>Ingresar a la dirección IP http://192.168.4.1 la cual cargará la página de administración</li>
    </nl>
    <h5>Comandos</h5>
    <p>
        La interfaz del navegador ofrece los siguientes menús:
    </p>
    <nl>
        <li><b>[ SETTINGS ]</b>, permite configurar diversos parámetros. Ver más abajo</li>
        <li><b>[ FILE MANAGER ]</b>, lista los programas presentes en el dispositivo y además algunos archivos de configuración</li>
        <li><b>[ EDIT ]</b>, permite editar un programa</li>
        <li><b>[ RUN ]</b>, permite ejecutar el programa que se encuentra activo en el editor</li>
        <li><b>[ DEBUG ]</b>, permite depurar paso a paso el programa que se encuentra en ejecución</li>
        <li><b>[ VARS ]</b>, permite visualizar las variables en ejecución en el programa actual</li>
    </nl>
    <h5>Configuración</h5>
    <p>
        El menú de SETTINGS permite configurar las siguientes opciones:
    </p>
    <nl>
        <li><b>Station Mode (Connect to router)</b>, permite conectar el dispositivo a una red WiFi mediante los campos "Name" y "Pass" para poder acceder a este desde una red local</li>
        <li><b>Ap mode (brocast out its own ap)</b>, permite modificar el nombre de la WiFi que genera el dispositivo</li>
        <li><b>Run default.bas at startup</b>, permite definir que el programa "default.bas" se ejecuta al iniciar el dispositivo</li>
    </nl>
    <p>
        Para guardar los cambios se debe presionar el botón "Save". Se recomienda no modificar los otros campos.
    </p>
    <h5>Ejemplos</h5>
    <p>
        <b>Ejemplo 1: Blink (parapedeo) de LED</b>.<br>Programa que enciende y apagada el LED cada 1 segundo. Para ello se asigna un pin para el led (D4 en el caso del ESP8266) y se define una variable que mantiene el estado del led (status). Luego se activa un temporizador que cambia el estado y activa el pin del led de acuerdo al valor establecido. El botón "End" es sólo para detener el prgrama.
    </p>
    <p>
        <pre class="grey lighten-2">
led = D4
status = 0
button "End", [Stop]
timer 1000, [Blink]
wait

[Blink]
if status = 0 then
  status = 1
else
  status = 0
endif
print status
io(po, led, status)
wait

[Stop]
end
</pre>
    </p>
    <br>
    <br>
</div>
</template>

<script>
export default {
    data() {
        return {}
    },
    mounted() {
        this.$g.busy = false;
    }
};
</script>

<style scoped></style>
