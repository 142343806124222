<template>
<div class="container" v-if="user">
    <div class="row">
        <h4 class="header col m12">Mi Perfil</h4>
    </div>

    <div class="row" v-show="!$g.busy">
        <div class="col s12">
            <h5>Datos del destinatario</h5>
            Para modificar utilice el formulario de abajo
            <div class="card">
                <div class="card-content" v-html="formatProfile(data)"> </div>
            </div>
        </div>
    </div>

    <div class="row" v-show="!$g.busy">
        <div class="col m8 s12">
            <ProfileEditor v-model="data" ref="proEditor"></ProfileEditor>
        </div>
        <div class="col m4 s12">
            <a class="waves-effect waves-light btn right" @click.prevent="saveProfile"><i class="material-icons left">save</i>Guardar</a>
        </div>
    </div>

    <div class="row" v-show="!$g.busy">
        <div class="col m12">
            <a class="waves-effect waves-light btn right hide-on-small-only" @click.prevent="saveProfile"><i class="material-icons left">save</i>Guardar</a>
        </div>
    </div>

    <div class="row" v-show="!$g.busy">
        <div class="col s12">
            <div class="card-panel">
                <h5 class="red-text lighten-2">Zona peligrosa</h5>
                Si desea eliminar su cuenta y todo su contenido (carro de compra, órdenes, perfil, etc), presione el botón de abajo. <b>Esta acción es definitiva y no se puede deshacer!</b>
                <p>
                    <a class="waves-effect waves-light btn red lighten-2 white-text" @click.prevent="deleteAccount"><i class="material-icons left">delete</i>Eliminar cuenta</a>
                </p>
            </div>
        </div>
    </div>
</div>
<FooterView />
</template>

<script>
import {
    Base,
    auth
} from '../base'
import {
    signOut
} from "../firebase";
import M from 'materialize-css'
import CartModel from '../models/CartModel'
import OrderModel from '../models/OrderModel'
import ProfileModel from '../models/ProfileModel'
import ProfileEditor from './ProfileEditorView.vue'

var cart = new CartModel();
var orders = new OrderModel();
var profile = new ProfileModel();

export default {
    mixins: [Base],
    data() {
        return {
            data: {}
        }
    },
    components: {
        ProfileEditor
    },
    methods: {
        async deleteAccount() {
            if (confirm('Desea eliminar la cuenta y todos los datos asociados?') == false)
                return;
            this.$g.busy = true;
            cart.clear();
            await orders.clear(this.user.uid);
            await profile.delete(this.user.uid);
            await signOut(auth);
            this.toast('Cuenta eliminada', 'Su cuenta ha sido eliminada del sistema');
            this.$g.busy = false;
            this.$router.push('/');
        },
        async saveProfile() {
            this.$g.busy = true;
            await profile.set(this.user.uid, this.data);
            this.$g.busy = false;
            this.showMessage('Perfil guardado', 'Los datos han sido guardados correctamente');
        },
        async onAuth() {
            this.data = await profile.get(this.user);
            this.$nextTick(() => {
                M.updateTextFields();
                if (this.$refs.proEditor) {
                    this.$refs.proEditor.initForm();
                }
            })
            this.$g.busy = false;
        }
    }
};
</script>

<style scoped></style>
