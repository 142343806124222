import {
    getAuth,
} from "./firebase";
import {
    // signInWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithPopup
} from "./firebase";
import M from 'materialize-css';
import FooterView from './components/FooterView.vue'
import moment from 'moment'
import CartModel from './models/CartModel'

export const auth = getAuth();

const formatoMoneda = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP'
});
const formatoNumero = new Intl.NumberFormat('es-CL');
var modal;
var cart = new CartModel();

export const Base = {
    data() {
        return {
            user: null,
            admin: false,
            message: {}
        };
    },
    components: {
        FooterView
    },
    emits: ['onAuth'],
    computed: {
        calcSum() {
            var sum = 0;
            if (this.data) {
                for (var x of this.data) {
                    sum += x.Total;
                }
            }
            return sum;
        }
    },
    methods: {
        login(callback) {
            const provider = new GoogleAuthProvider();
            provider.setCustomParameters({
                'prompt': 'consent'
            });
            signInWithPopup(auth, provider)
                .then((result) => {
                    // const credential = GoogleAuthProvider.credentialFromResult(result);
                    // const token = credential.accessToken;
                    self.user = result.user;
                    if (callback) callback();
                    // console.log(self.user);
                }).catch((error) => {
                    // const errorCode = error.code;
                    // const errorMessage = error.message;
                    // const email = error.customData.email;
                    // const credential = GoogleAuthProvider.credentialFromError(error);
                    alert("Invalid access: " + error.message);
                });
        },
        // login() {
        //     var self = this;
        //     if (!this.form.username || !this.form.password) {
        //         alert('Debe ingresar las credenciales');
        //         return;
        //     }
        //     signInWithEmailAndPassword(auth, this.form.username, this.form.password)
        //         .then((userCredential) => {
        //             self.user = userCredential.user;
        //             modal2.close();
        //         })
        //         .catch((error) => {
        //             // const errorCode = error.code;
        //             // const errorMessage = error.message;
        //             alert("Invalid access: " + error.message);
        //         });
        // },
        formatProfile(data) {
            var buf = "";
            if (data) {
                if (data.Name) {
                    buf += '<strong>Sr(a) ' + data.Name + '</strong><br>';
                }
                if (data.Phone) {
                    buf += 'Teléfono: ' + data.Phone + '<br>';
                }
                if (data.Email) {
                    buf += 'Email: ' + data.Email + '<br>';
                }
                if (data.Address) {
                    buf += 'Dirección: ';
                    if (data.Address.Calle) {
                        buf += data.Address.Calle;
                    }
                    if (data.Address.Numero) {
                        buf += ' ' + data.Address.Numero;
                    }
                    if (data.Address.Depto) {
                        buf += ' Depto. ' + data.Address.Depto;
                    }
                    if (data.Address.Comuna.Nombre) {
                        buf += '<br>Comuna de ' + data.Address.Comuna.Nombre;
                    }
                    if (data.Address.Region.Nombre) {
                        buf += '<br>Región ' + data.Address.Region.Nombre;
                    }
                }
            }
            return buf;
        },
        toast(msg) {
            M.toast({ html: `<i class="material-icons">check</i>&nbsp;${msg}`, classes: 'teal lighten-2' })
        },
        resizeTextArea(tag) {
            var elem = document.getElementById(tag);
            M.textareaAutoResize(elem);
        },
        updateTextFields() {
            M.updateTextFields();
        },
        initCarousel(options) {
            var elems = document.querySelectorAll('.carousel');
            M.Carousel.init(elems, options);
        },
        initModal(tag, options) {
            var elem = document.getElementById(tag);
            return M.Modal.init(elem, options);
        },
        showMessage(title, message) {
            this.$g.message = {
                title: title,
                message: message
            }
            modal.open();
        },
        formatNumber(n) {
            if (!n || n == 0) return '-';
            return formatoNumero.format(n);
        },
        formatMoney(n) {
            if (!n || n == 0) return '-';
            return formatoMoneda.format(n);
        },
        formatDateTime(d) {
            return moment(d).format('DD/MM/YYYY HH:mm')
        },
        async buy(item) {
            cart.buy(item);
            this.toast('Producto agregado al carro de compras');
        }
    },
    mounted() {
        this.$g.busy = true;
        M.AutoInit()
        var elem = document.getElementById('modal1');
        modal = M.Modal.init(elem);
        var self = this;
        auth.onAuthStateChanged(user => {
            self.user = user;
            if (user == null) return;
            this.admin = process.env.VUE_APP_USER_ADMIN == user.email;
            if (self.onAuth) {
                self.onAuth(user);
            }
        });
    }
}