<template>
<div class="container" v-if="user">
    <h4 class="header">Mis Pedidos</h4>
    <div v-show="!$g.busy">
        <div v-if="!(data && data[0])" class="card-panel">No se han encontrado pedidos vigentes</div>
        <table v-else class="table">
            <thead>
                <tr>
                    <th>Orden</th>
                    <th class="right-align">Fecha</th>
                    <th class="right-align">Estado</th>
                    <th class="right-align">Total</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in data" :key="item.Key">
                    <td>
                        <router-link :to="'/order/' + item.Key">{{item.Key}}</router-link>
                    </td>
                    <td class="right-align">{{ formatDateTime(item.Date) }}</td>
                    <td class="right-align">{{ item.Status }}</td>
                    <td class="right-align">{{ formatMoney(item.Total) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<FooterView />
</template>

<script>
import {
    Base
} from '../base'
import {
    where
} from "../firebase";
import OrderModel from '../models/OrderModel'

var orders = new OrderModel();

export default {
    mixins: [Base],
    data() {
        return {
            data: []
        }
    },
    methods: {
        async onAuth() {
            this.data = await orders.list(this.user.uid, where('Status', '!=', 'PENDIENTE'));
            this.$g.busy = false;
        }
    }
};
</script>

<style scoped>
</style>
